<template>
  <div class="bg-dark-primary text-white">
    <AppHeader />
    <router-view />
  </div>
</template>

<script setup>
import AppHeader from "@/components/AppHeader.vue"
import { useAuth0 } from "@auth0/auth0-vue"
import { onMounted } from "vue"
import { useRouter } from "vue-router"

const router = useRouter();

const { getAccessTokenSilently, isAuthenticated } = useAuth0();

onMounted(async () => {
  if (isAuthenticated.value) {
    try {
      const token = await getAccessTokenSilently({
        audience: `${process.env.VUE_APP_AUTH0_AUDIENCE}`,
      });
      sessionStorage.setItem('token', token);

    } catch (error) {
      console.error("Error al obtener el token:", error);
      router.push('/');
    }
  } else {
    router.push('/');
  }
});

</script>