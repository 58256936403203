<script setup>
import { useMediaQuery } from "@vueuse/core";
import { defineEmits, defineProps, ref, watch, computed } from "vue";
import Arrow from "./icons/arrowS.vue";
import MenuS from "./icons/menuS.vue";
import templateIcon from "./icons/template.vue";

const props = defineProps({
  modules: {
    type: Array,
    required: true,
    default: () => [],
  },
  title: {
    type: String,
    required: true,
  },
  currentLessonId: {
    type: Number,
    required: true,
  },
});

const isLargeScreen = useMediaQuery("(max-width: 768px)");

const showModulesMovil = ref(true);
const emit = defineEmits(["lessonSelected"]);
const activeModuleIndex = ref(null);

function selectLesson(lesson) {
  emit("lessonSelected", lesson);
}

function selectModule(index) {
  // Toggle the module - if it's already active, set to null (closed)
  activeModuleIndex.value = activeModuleIndex.value === index ? null : index;
}

watch(isLargeScreen, (newValue) => {
  if (newValue) {
    showModulesMovil.value = false;
  }
});

const currentModuleIndex = computed(() => {
  return props.modules.findIndex(module =>
    module.lessons.some(lesson => lesson.id === props.currentLessonId)
  );
});

// Modified to only set activeModuleIndex if it's not already set
watch(() => props.currentLessonId, () => {
  if (currentModuleIndex.value !== -1 && activeModuleIndex.value === null) {
    activeModuleIndex.value = currentModuleIndex.value;
  }
}, { immediate: true });
</script>

<template>
  <div class="sidebar border-r-primary-dark py-3">
    <div
      class="sidebar-header border-primary-dark pt-2 pb-2 mb-lg-3 d-flex justify-content-start"
    >
      <div class="text-start w-100 padding-lef">
        <h6>{{ props.title }}</h6>
      </div>
      <div
        @click="showModulesMovil = !showModulesMovil"
        class="w-33 menu-xs align-items-center justify-content-end mb-3 me-3"
      >
        <MenuS />
      </div>
    </div>
    <div v-if="showModulesMovil">
      <div
        v-for="(module, index) in props.modules"
        :key="index"
        class="border-primardark"
      >
        <h6
          @click="selectModule(index)"
          :class="{ 'bg-cousers': activeModuleIndex === index }"
          class="module-title d-flex justify-content-between px-3 py-3 mb-0"
        >
          <div>
            <templateIcon
              :class="{ 'color-active': activeModuleIndex === index }"
              class="me-2"
            />
            {{ module.title }}
          </div>
          <div class="d-flex align-items-center gap-3">
            <p class="mb-0 text-length" v-text="`1/${module.lessons.length}`" />
            <Arrow :class="{ 'rotate-180': activeModuleIndex === index }" />
          </div>
        </h6>

        <ul
          class="list-unstyled mb-0 content-lessons"
          :class="{ active: activeModuleIndex === index }"
        >
          <li
            v-for="lesson in module.lessons"
            :key="lesson.id"
            @click="selectLesson(lesson)"
            class="mb-0"
          >
            <p
              :class="{ 'active-lesson': lesson.id === props.currentLessonId }"
              class="lesson-title relative mb-0"
            >
              {{ lesson.title }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.padding-lef{
  padding-left: 5%;
}
.w-33 {
  width: 33%;
}
.sidebar {
  height: calc(100vh - 6px);
  overflow-y: auto;
}
.sidebar-header {
  text-align: center;
}
.module-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.sidebar ul {
  padding-left: 0;
}
.sidebar ul li {
  padding: 16px 45px;
  border-top: 1px solid #333333;
  background: #272a2f;
  cursor: pointer;
  align-items: center;
}
.sidebar ul li a {
  color: #28a745;
  text-decoration: none;
}
.lesson-title::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid green;
  display: inline-block;
  margin-right: 10px;
  color: green;
  margin-bottom: -3px;
}
.lesson-title.active-lesson::before {
  background: green;
  border: 1px solid green;
}
.text-length {
  font-size: 12px;
  font-weight: 400;
}
.rotate-180 {
  transform: rotate(180deg);
  transition: all;
  transition-duration: 0.3s;
}
.color-active {
  color: #28a745;
}
.content-lessons {
  max-height: 0;
  overflow: hidden;
  transition: all;
  transition-duration: 0.3s;
}
.content-lessons.active {
  max-height: max-content;
}
.menu-xs {
  display: none;
}

@media (max-width: 768px) {
  .menu-xs {
    display: flex;
  }
}
</style>