import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Lesson from '../views/LessonView.vue'
import Test from '../views/TestView.vue'
import { createAuthGuard } from "@auth0/auth0-vue"

import App from "vue"


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: createAuthGuard(App),
    children: [
      {
        path: 'lesson/:id',
        name: 'Lesson',
        component: Lesson,
        meta: { requiresAuth: true },
      }
    ]
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


export default router