import axios from "axios";
import { defineStore } from "pinia";

export default defineStore({
  id: "lesson",
  state: () => ({
    _status: null,
    _error: null,
    _currentCourse: null,
    _currentLesson: null,
    _currentModuleIndex: 0,
    _currentLessonIndex: 0,
    _currentLessonNext: null,
    _currentLessonPrev: null,
    _isConcluded: false,
  }),
  getters: {
    isLoading: (state) => state._status === "loading",
    isReady: (state) => state._status === "ready",
    isError: (state) => state._status === "error",
    error: (state) => state._error,
    currentCourse: (state) => state._currentCourse,
    currentLesson: (state) => state._currentLesson,
    currentLessonNext: (state) => state._currentLessonNext,
    currentLessonPrev: (state) => state._currentLessonPrev,
    isConcluded: (state) => state._isConcluded,
  },
  actions: {
    async getLesson(_id) {
      try {
        this.changeStatus("loading");
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/products/${_id}/product-permission/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        this._currentModuleIndex = 0;
        this._currentLessonIndex = 0;
        this._currentCourse = response.data;
        if (response.data.modules[0] && response.data.modules[0].lessons[0]) {
          this._currentLesson = response.data.modules[0].lessons[0];
          this.updateNavigationLessons();
        }

        this.changeStatus("ready");
      } catch (error) {
        if (error.response?.status === 404) {
          this.changeStatus("error", "Producto no encontrado");
        } else if (error.response?.data?.error) {
          window.location.replace(
            `${process.env.VUE_APP_MARKET_URL}/products/buy/${_id}`
          );
        } else {
          this.changeStatus("error", "Error al cargar la lección");
        }
        console.error("Error fetching product:", error);
      }
    },

    async concludeLesson(_id, rating, retryCount = 3) {
      if (!_id || typeof rating !== 'number' || rating < 1 || rating > 5) {
        throw new Error('Parámetros de conclusión de lección inválidos');
      }

      try {
        this.changeStatus("loading");
        const response = await axios.post(
          `${process.env.VUE_APP_API_URL}/user-lesson/`,
          { lesson_id: _id, points: rating },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200 || response.status === 201) {
          this._isConcluded = true;
          if (this._currentLesson) {
            this._currentLesson.points = rating;
          }
        }
        this.changeStatus("ready");
      } catch (error) {
        if (retryCount > 0 && error.response?.status >= 500) {
          console.log(`Retrying conclusion... Attempts left: ${retryCount - 1}`);
          await new Promise(resolve => setTimeout(resolve, 1000));
          return this.concludeLesson(_id, rating, retryCount - 1);
        }
        console.error("Error concluding lesson:", error);
        this.changeStatus("error", "Error al concluir la lección");
        throw error;
      }
    },

    async getConcludedLesson(_id) {
      if (!_id) {
        throw new Error('ID de lección inválido');
      }

      try {
        this.changeStatus("loading");
        const response = await axios.get(
          `${process.env.VUE_APP_API_URL}/user-lesson/${_id}/`,
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("token")}`,
            },
          }
        );
        if (response.status === 200) {
          this._isConcluded = response.data.completed;
          if (this._isConcluded && this._currentLesson) {
            this._currentLesson.points = response.data.points;
          }
        }

        this.changeStatus("ready");
      } catch (error) {
        if (error.response?.status === 404) {
          console.log('Lesson not graded or completed yet');
          this._isConcluded = false;
          if (this._currentLesson) {
            this._currentLesson.points = 0;
          }
        } else {
          console.error("Error fetching concluded lesson:", error);
          this.changeStatus("error", "Error al verificar el estado de la lección");
          throw error;
        }
      }
    },

    updateNavigationLessons() {
      if (!this._currentCourse || !this._currentLesson) return;

      // Calculate previous lesson
      if (this._currentLessonIndex > 0) {
        // If not first lesson in module, get previous lesson in same module
        this._currentLessonPrev = this._currentCourse.modules[this._currentModuleIndex].lessons[this._currentLessonIndex - 1];
      } else if (this._currentModuleIndex > 0) {
        // If first lesson in module but not first module, get last lesson of previous module
        const prevModule = this._currentCourse.modules[this._currentModuleIndex - 1];
        this._currentLessonPrev = prevModule.lessons[prevModule.lessons.length - 1];
      } else {
        // If first lesson of first module, no previous lesson
        this._currentLessonPrev = null;
      }

      // Calculate next lesson
      const currentModule = this._currentCourse.modules[this._currentModuleIndex];
      if (this._currentLessonIndex < currentModule.lessons.length - 1) {
        // If not last lesson in module, get next lesson in same module
        this._currentLessonNext = currentModule.lessons[this._currentLessonIndex + 1];
      } else if (this._currentModuleIndex < this._currentCourse.modules.length - 1) {
        // If last lesson in module but not last module, get first lesson of next module
        this._currentLessonNext = this._currentCourse.modules[this._currentModuleIndex + 1].lessons[0];
      } else {
        // If last lesson of last module, no next lesson
        this._currentLessonNext = null;
      }
    },

    nextLesson() {
      const currentModule = this._currentCourse.modules[this._currentModuleIndex];
      if (!currentModule) return;

      if (this._currentLessonIndex < currentModule.lessons.length - 1) {
        this._currentLessonIndex++;
        this._currentLesson = currentModule.lessons[this._currentLessonIndex];
      } else if (this._currentModuleIndex < this._currentCourse.modules.length - 1) {
        this._currentModuleIndex++;
        this._currentLessonIndex = 0;
        const nextModule = this._currentCourse.modules[this._currentModuleIndex];
        this._currentLesson = nextModule.lessons[0];
      }
      
      this.updateNavigationLessons();
      
      if (this._currentLesson) {
        this.getConcludedLesson(this._currentLesson.id);
      }
    },

    prevLesson() {
      if (this._currentLessonIndex > 0) {
        this._currentLessonIndex--;
        const currentModule = this._currentCourse.modules[this._currentModuleIndex];
        this._currentLesson = currentModule.lessons[this._currentLessonIndex];
      } else if (this._currentModuleIndex > 0) {
        this._currentModuleIndex--;
        const prevModule = this._currentCourse.modules[this._currentModuleIndex];
        this._currentLessonIndex = prevModule.lessons.length - 1;
        this._currentLesson = prevModule.lessons[this._currentLessonIndex];
      }

      this.updateNavigationLessons();

      if (this._currentLesson) {
        this.getConcludedLesson(this._currentLesson.id);
      }
    },

    setCurrentLesson(lesson) {
      if (!lesson) return;
      
      this._currentLesson = lesson;
      // Find the module and lesson indices
      this._currentModuleIndex = this._currentCourse.modules.findIndex(
        module => module.lessons.some(l => l.id === lesson.id)
      );
      
      if (this._currentModuleIndex !== -1) {
        const currentModule = this._currentCourse.modules[this._currentModuleIndex];
        this._currentLessonIndex = currentModule.lessons.findIndex(
          l => l.id === lesson.id
        );
        
        // Update navigation lessons
        this.updateNavigationLessons();
        
        // Get lesson completion status
        this.getConcludedLesson(lesson.id);
      }
    },

    changeStatus(status, error = null) {
      this._status = status;
      this._error = error;
    },
  },
});