<template>
  <div class="lesson-player">
    <div class="d-none justify-content-between mt-2">
      <h2 class="lesson-title">{{ lessonStore.prev?.title }}</h2>
      <h2 class="lesson-title">{{ lessonStore.currentLessonNext?.title }}</h2>
    </div>
    
    <!-- When there is video: Video -> Title/Rating -> Content -->
    <template v-if="!noVideo">
      <div class="video-wrapper mb-3">
        <iframe
          :src="videoSrc"
          frameborder="0"
          allow="encrypted-media"
          allowfullscreen
          ref="videoIframe"
        ></iframe>
      </div>

      <!-- Title, rating and complete button -->
      <div class="d-lg-flex text-start align-items-start justify-content-between mt-4">
        <div>
          <p class="current-course mb-3">
            {{ lessonStore?.currentLesson?.title }}
          </p>

          <div class="d-flex align-items-center mb-3">
            <p class="me-4 mb-0">Evalúa esta clase:</p>
            <StartIcon 
              v-for="i in 5" 
              :key="i" 
              :filled="i <= rating"
              @click="setRating(i)" 
              :class="lessonStore.isConcluded ?? `me-2 star-icon` " 
            />
          </div>
        </div>
        <button 
          class="btn-conclude" 
          :class="{ 'btn-conclude-active': lessonStore.isConcluded }"
          @click="markAsCompleted"
          :disabled="isSubmitting || lessonStore.isConcluded"
        >
          <p class="mb-0">
            {{ isSubmitting ? 'Procesando...' : (lessonStore.isConcluded ? 'Lección completada' : 'Marcar como concluida') }}
          </p>
        </button>
      </div>

      <!-- Content after title/rating when there is video -->
      <div class="mt-4">
        <p>{{ lessonStore?.currentLesson?.content }}</p>
      </div>
    </template>

    <!-- When there is no video: Content -> Title/Rating -->
    <template v-else>
      <div>
        <p>{{ lessonStore?.currentLesson?.content }}</p>
      </div>

      <!-- Title, rating and complete button -->
      <div class="d-lg-flex text-start align-items-start justify-content-between mt-4">
        <div>
          <p class="current-course mb-3">
            {{ lessonStore?.currentLesson?.title }}
          </p>

          <div class="d-flex align-items-center mb-3">
            <p class="me-4 mb-0">Evalúa esta clase:</p>
            <StartIcon 
              v-for="i in 5" 
              :key="i" 
              :filled="i <= rating"
              @click="setRating(i)" 
              :class="lessonStore.isConcluded ?? `me-2 star-icon` " 
            />
          </div>
        </div>
        <button 
          class="btn-conclude" 
          :class="{ 'btn-conclude-active': lessonStore.isConcluded }"
          @click="markAsCompleted"
          :disabled="isSubmitting || lessonStore.isConcluded"
        >
          <p class="mb-0">
            {{ isSubmitting ? 'Procesando...' : (lessonStore.isConcluded ? 'Lección completada' : 'Marcar como concluida') }}
          </p>
        </button>
      </div>
    </template>

    <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>

    <!-- Rating Reminder Modal -->
    <div v-if="showRatingModal" class="modal-overlay" @click="closeRatingModal">
      <div class="modal-content" @click.stop>
        <p>Debes calificar esta lección antes de marcarla como completada</p>
        <button class="modal-btn" @click="closeRatingModal">Entendido</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import axios from "axios"
import { storeToRefs } from "pinia"
import { defineProps, ref, watch, onMounted, onUnmounted } from "vue"
import LessonStore from "../stores/lesson.js"
import StartIcon from "./icons/startIcon.vue"

const props = defineProps({
  productId: {
    required: true,
  },
});

const lessonStore = LessonStore();
const { _currentLesson } = storeToRefs(lessonStore);

const noVideo = ref(false);
const videoSrc = ref("");
const errorMessage = ref("");
const videoIframe = ref(null);
const rating = ref(0);
const showRatingModal = ref(false);
const isSubmitting = ref(false);

watch(_currentLesson, async (newLesson) => {
  if (newLesson?.video_id) {
    await getVdoCipherCredentials(newLesson.video_id);
  } else {
    noVideo.value = true;
  }
  // Reset state for new lesson
  errorMessage.value = "";
  await fetchLessonRating(newLesson.id);
});

onMounted(async () => {
  if (_currentLesson.value?.id) {
    await fetchLessonRating(_currentLesson.value.id);
  }
});

onUnmounted(() => {
  errorMessage.value = '';
  showRatingModal.value = false;
});

async function getVdoCipherCredentials(videoId) {
  try {
    noVideo.value = false;
    const apiUrl = process.env.VUE_APP_API_URL;
    const payload = {
      video_id: videoId,
      product_id: props.productId,
    };

    const response = await axios.post(
      `${apiUrl}/get_vdocipher_credentials/`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("token")}`,
        },
      }
    );
    const { otp, playbackInfo } = await response.data;
    videoSrc.value = `https://player.vdocipher.com/v2/?otp=${otp}&playbackInfo=${playbackInfo}`;
  } catch (error) {
    console.error('Error fetching video credentials:', error);
    noVideo.value = true;
    errorMessage.value = "Error al cargar el video";
  }
}

async function fetchLessonRating(lessonId) {
  try {
    await lessonStore.getConcludedLesson(lessonId);
    rating.value = _currentLesson.value?.points || 0;
    console.log(`Fetched rating for lesson ${lessonId}: ${rating.value}`);
  } catch (error) {
    console.error('Error fetching lesson rating:', error);
    rating.value = 0;
  }
}

function setRating(value) {
  if(lessonStore.isConcluded) return
  rating.value = value;
  errorMessage.value = ""; // Clear any previous errors
  console.log(`Star ${value} clicked. Current rating: ${rating.value}`);
}

async function markAsCompleted() {
  if (rating.value === 0) {
    showRatingModal.value = true;
    return;
  }
  if (lessonStore.isConcluded) return
  if (isSubmitting.value) return;

  try {
    isSubmitting.value = true;
    errorMessage.value = "";
    
    await lessonStore.concludeLesson(_currentLesson.value.id, rating.value);
    console.log('Lesson marked as completed successfully');
  } catch (error) {
    console.error('Error marking lesson as completed:', error);
    errorMessage.value = "Error al marcar la lección como completada";
  } finally {
    isSubmitting.value = false;
  }
}

function closeRatingModal() {
  showRatingModal.value = false;
}
</script>

<style scoped>
.lesson-player {
  text-align: center;
  margin-bottom: 6px;
}

.lesson-title {
  font-size: 18px;
  margin-bottom: 10px;
  text-align: start;
}

.video-wrapper {
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: 100%;
}

.video-wrapper iframe {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@media (min-width: 1500px) {
  .video-wrapper {
    padding-bottom: 48.25%;
  }
}

.lesson-description {
  margin-top: 10px;
  font-size: 16px;
}

.text-danger {
  color: #dc3545;
}

.current-course {
  text-align: start;
  font-size: 20px;
  font-weight: 500;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #0D1117;
  padding: 2rem;
  border-radius: 8px;
  text-align: center;
  max-width: 90%;
  width: 400px;
}

.modal-btn {
  background-color: #FA7D5E;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 1rem;
  transition: background-color 0.3s ease;
}

.btn-conclude {
  border: 1px solid #22c55e;
  background: transparent;
  color: #22c55e;
  border-radius: 6px;
  padding: 11px;
  transition: all 0.3s ease;
}

.btn-conclude:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.btn-conclude-active {
  background-color: #22c55e;
  color: white;
}

.btn-conclude p::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid currentColor;
  display: inline-block;
  margin-right: 10px;
  margin-bottom: -3px;
}

.btn-conclude-active p::before {
  background-color: white;
  border-color: white;
}

.star-icon {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.star-icon:hover {
  transform: scale(1.1);
}
</style>